<template>
  <div class="games">
    <b-container class="py-3">
      <page-title title="Játékok">
        <template #action>
          <b-button
            :disabled="!uid"
            :to="{ name: 'CreateGame' }"
            variant="primary"
          >
            Új játék
          </b-button>
        </template>
      </page-title>

      <b-overlay :show="!gameModuleLoaded" no-wrap spinner-variant="primary" />
      <div v-if="gameModuleLoaded">
        <div class="mb-3">
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                description="Keresés a játékok nevében, lead-jében és leírásában"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <b-icon-search />
                  </b-input-group-prepend>
                  <b-form-input
                    type="search"
                    placeholder="Keress!"
                    v-model="filter.searchTerm"
                    debounce="250"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" lg="3">
              <b-form-group>
                <b-input-group>
                  <b-input-group-addon is-text>
                    <b-icon-person-badge />
                  </b-input-group-addon>
                  <b-form-select
                    v-model="filter.ageLimit.relation"
                    :options="['=', '<', '>']"
                  />
                  <b-form-input
                    type="number"
                    v-model="filter.ageLimit.value"
                    placeholder="Korhatár"
                    debounce="250"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" lg="3">
              <b-form-group>
                <b-input-group>
                  <b-input-group-addon is-text>
                    <b-icon-people />
                  </b-input-group-addon>
                  <b-form-input
                    v-model="filter.playerCount.value"
                    type="number"
                    placeholder="Játékosok"
                    debounce="250"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="filterSet">
            <page-title title="Keresési találatok" titleTag="h5">
              <template #action>
                <span class="text-primary" v-if="filteredGames.length">
                  <strong>
                    {{ filteredGames.length }}
                  </strong>
                  találat
                </span>
                <b-button variant="link" size="sm" @click="clearFilters">
                  <b-icon-x-circle />
                </b-button>
              </template>
            </page-title>
            <p v-if="filteredGames.length" class="text-muted">
              <small> Keresés: <span v-html="filterDisplay" /> </small>
            </p>
            <b-alert variant="danger" :show="!filteredGames.length">
              Nincs találat erre a keresésre:
              <span v-html="filterDisplay" />
            </b-alert>
            <b-list-group>
              <b-list-group-item
                v-for="game in filteredGames"
                :key="game.id"
                :to="{
                  name: 'ViewGame',
                  params: { gamePath: game.content.path }
                }"
              >
                <strong>
                  {{ game.content.name }}
                </strong>
                <br />
                <small>
                  {{ displayUrl(game.content.url) }}
                </small>
                <br />
                <small>
                  {{ game.content.lead }}
                </small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>

        <page-title title="Összes játék" titleTag="h5">
          <template #action>
            <b-dropdown :text="$t(`sort-by-${sortBy}`)" variant="link">
              <b-dropdown-item
                v-for="option in ['name', 'createdAt', 'rating', 'ratingCount']"
                :key="option"
                :active="sortBy === option"
                @click="sortBy = option"
                >{{ $t(`sort-by-${option}`) }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </page-title>
        <b-row>
          <template v-for="game in sortedGames">
            <b-col cols="12" sm="6" lg="4" :key="game.id" class="mb-3">
              <game-card :game="game" />
            </b-col>
            <b-col
              cols="12"
              sm="6"
              lg="4"
              :key="`ad-${game.id}`"
              class="mb-3"
              v-if="!headless && showAdRnd()"
            >
              <ad />
            </b-col>
          </template>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";
import displayUrl from "@/utils/displayUrl";
import GameModuleMixin from "../mixins/GameModuleMixin";
import AuthMixin from "../mixins/AuthMixin";
import GameCard from "../components/GameCard.vue";
import saveState from "vue-save-state";
import Ad from "../components/Ad.vue";

export default {
  name: "Games",
  components: { PageTitle, GameCard, Ad },
  mixins: [GameModuleMixin, saveState, AuthMixin],
  data: () => ({
    filter: {
      searchTerm: null,
      ageLimit: { value: null, relation: "<" },
      playerCount: { value: null }
    },
    sortBy: "rating"
  }),
  mounted() {
    const { kereses, korhatar, jatekosok } = this.$route.query;
    if (kereses) this.filter.searchTerm = kereses;
    if (korhatar) {
      const relation = korhatar[0];
      const value = parseInt(korhatar.substring(1));
      if (["=", "<", ">"].includes(relation) && !isNaN(value))
        this.filter.ageLimit = { value, relation };
    }
    if (!isNaN(parseInt(jatekosok)))
      this.filter.playerCount.value = parseInt(jatekosok);
  },
  computed: {
    filterDisplay() {
      const filters = [];
      if (this.filter.searchTerm)
        filters.push(`<strong>"${this.filter.searchTerm}"</strong>`);
      if (!isNaN(parseInt(this.filter.ageLimit.value)))
        filters.push(`korhatár <strong>${this.filter.ageLimit.relation} \
          ${this.filter.ageLimit.value}</strong> év`);
      if (!isNaN(parseInt(this.filter.playerCount.value)))
        filters.push(`<strong>${this.filter.playerCount.value}</strong>\
          játékos`);
      return filters.join(", ");
    },
    filterSet() {
      return (
        !!this.filter.searchTerm ||
        !!this.filter.ageLimit.value ||
        this.filter.ageLimit.value === 0 ||
        !!this.filter.playerCount.value ||
        this.filter.playerCount.value === 0
      );
    },
    filteredGames() {
      if (!this.filterSet) return [];

      const searchTermMatch = (c) => {
        if (!this.filter.searchTerm) return true;
        try {
          const re = new RegExp(this.filter.searchTerm, "i");
          const match = {
            name: !!c.name.match(re),
            url: !!c.url.match(re),
            lead: !!c.lead.match(re),
            description: !!JSON.stringify(c.description || {}).match(re)
          };
          return Object.values(match).includes(true);
        } catch (error) {
          console.log(error);
          return false;
        }
      };

      const ageLimitMatch = (c) => {
        if (!this.filter.ageLimit.value) return true;
        const al = parseInt(c.ageLimit);
        if (isNaN(al)) return false;
        switch (this.filter.ageLimit.relation) {
          case "=":
            return al === this.filter.ageLimit.value;
          case "<":
            return al < this.filter.ageLimit.value;
          case ">":
            return al > this.filter.ageLimit.value;
        }
      };

      const playerCountMatch = (c) => {
        if (!this.filter.playerCount.value) return true;
        if (c.playerCount === undefined || c.playerCount === null) return false;
        const from = parseInt(c.playerCount.from);
        const to = parseInt(c.playerCount.to);
        const lowerOk = isNaN(from) || from <= this.filter.playerCount.value;
        const upperOk = isNaN(to) || to >= this.filter.playerCount.value;
        return lowerOk && upperOk;
      };

      return this.gameModuleItems.filter((game) => {
        if (!game.content) return false;
        return (
          searchTermMatch(game.content) &&
          ageLimitMatch(game.content) &&
          playerCountMatch(game.content)
        );
      });
    },
    sortedGames() {
      const filtered = [...this.gameModuleItems.filter((g) => !!g.content)];
      switch (this.sortBy) {
        case "name":
          return filtered.sort((a, b) => {
            const an = a.content.name.toLowerCase();
            const bn = b.content.name.toLowerCase();
            if (an < bn) return -1;
            if (an > bn) return 1;
            return 0;
          });
        case "createdAt":
          return filtered.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        case "rating":
          return filtered.sort((a, b) => {
            if (!a.rating.average) return 1;
            if (!b.rating.average) return -1;
            return b.rating.average - a.rating.average;
          });
        case "ratingCount":
          return filtered.sort((a, b) => b.rating.count - a.rating.count);
        default:
          return filtered;
      }
    }
  },
  methods: {
    clearFilters() {
      this.filter.searchTerm = this.filter.playerCount.value = this.filter.ageLimit.value = null;
    },
    getSaveStateConfig() {
      return {
        cacheKey: "games",
        saveProperties: ["sortBy"] //"searchTerm"
      };
    },
    showAdRnd() {
      const r = Math.random();
      return r < 1 / 8;
    },
    displayUrl
  },
  watch: {
    filter: {
      deep: true,
      async handler(f) {
        const query = {};
        if (f.searchTerm) query.kereses = f.searchTerm;
        if (f.ageLimit.value || f.ageLimit.value === 0)
          query.korhatar = f.ageLimit.relation + f.ageLimit.value;
        if (f.playerCount.value || f.playerCount.value === 0)
          query.jatekosok = f.playerCount.value;

        try {
          await this.$router.replace({ query });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>
